code[class*=language-], pre[class*=language-] {
  color: #000;
  background: 0 0;
  text-shadow: 0 1px #fff;
  //font-family: Consolas,Monaco,'Andale Mono','Ubuntu Mono',monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

code[class*=language-] {
  ::-moz-selection, &::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

pre[class*=language-] {
  ::-moz-selection, &::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

code[class*=language-] {
  ::selection, &::selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

pre[class*=language-] {
  ::selection, &::selection {
    text-shadow: none;
    background: #b3d4fc;
  }
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
}

@media print {
  code[class*=language-], pre[class*=language-] {
    text-shadow: none;
  }
}

:not(pre) > code[class*=language-], pre[class*=language-] {
  background: #f5f2f0;
}

:not(pre) > code[class*=language-] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

.token {
  &.cdata, &.comment, &.doctype, &.prolog {
    color: #708090;
  }
  &.punctuation {
    color: #999;
  }
}

.namespace {
  opacity: .7;
}

.token {
  &.boolean, &.constant, &.deleted, &.number, &.property, &.symbol, &.tag {
    color: #905;
  }
  &.attr-name, &.builtin, &.char, &.inserted, &.selector, &.string {
    color: #690;
  }
}

.language-css .token.string, .style .token.string {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token {
  &.entity, &.operator, &.url {
    color: #a67f59;
    background: hsla(0, 0%, 100%, 0.5);
  }
  &.atrule, &.attr-value, &.keyword {
    color: #07a;
  }
  &.function {
    color: #DD4A68;
  }
  &.important, &.regex, &.variable {
    color: #e90;
  }
  &.bold, &.important {
    font-weight: 700;
  }
  &.italic {
    font-style: italic;
  }
  &.entity {
    cursor: help;
  }
}

/*# sourceMappingURL=prism.min.css.map */